.typography-text {
    font-family: "Lora", serif;
    /* A classic serif font for a professional look */
    font-size: 1.1rem;
    width: 80%;
    /* Adjust based on design */
    line-height: 1.6;
    /* Improves readability */
    color: #c7fcec;
    /* Light color on dark backgrounds */
    text-align: justify;
    /* Align text for neat block formatting */
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 8px;
    /* Adds slight rounded corners */
}

.typography-heading {
    font-family: "Poppins", sans-serif;
    /* A sans-serif font for contrast in headings */
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #f4ecff;
}

.name-wrapper {
    padding: 10px;
    color: #e4e4e4;
    background-color: #622d6c;
    border-radius: 30px;
}
